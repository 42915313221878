import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"

const Layout = ({ children }) => {
  return (
    <div>
      <div className="portal-container">
        <Header />
          <div className="portal-item">
            {children}
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default Layout;