import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
// import "../styles/mystyles.scss"
import Img from "gatsby-image"
import MobileMenu from "../components/mobile_menu"

const Home = ({ data }) => {
  return (<Layout>
    <MobileMenu></MobileMenu>
    <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns">
      <div className="column m-6">
        <Img fluid={data.description.childImageSharp.fluid} alt="sample" className="my-6"/>
        <p className="title has-text-weight-light is-size-4 mb-6 pb-6">すべての教育機関 / 企業に贈る、新しい「学び」のかたち</p>
      </div>
    </div>
  </Layout>)
}

export const query = graphql`
query IndexPageQuery {
  description: file(relativePath: {eq: "booklooper_desc.png"}) {
    id
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

export default Home;
